import {ModulePages} from "../../core/module-pages";
import {CreateProfilePage} from "./page/create.profile.page";
import {EditProfilePage} from "./page/edit.profile.page";
import {ListProfilePage} from "./page/list.profile.page";

export function ProfileModule() {
  return (
    <ModulePages routes={[
      { path: 'create', render: (props) => <CreateProfilePage {...props} />},
      { path: 'edit/:id', render: (props) => <EditProfilePage {...props} />},
      { path: 'list', render: (props) => <ListProfilePage {...props} />},
      { path: '', render: (props) => <ListProfilePage {...props} />},
      ]}
    />
  )
}
