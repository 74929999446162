import {Button, Col, Form, Input, Modal, notification, Row, Select, Switch} from "antd";
import React, {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {ProcessTypeEntity} from "../../process-type/domain/entity/process-type.entity";
import {useProcessTypeRepository} from "../../process-type/domain/repository/process-type.repository";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {CrudProcessObjectAttachedComponent} from "./process-object-attached/crud-process-object-attached.component";
import {useProcessCodeUtil} from "../../../../../core/util/process-code.util";
import {CourtDropdown} from "../../../core/dropdown/court.dropdown";
import {SearchOutlined} from "@ant-design/icons";
import {useJbHttpTjService} from "../../../../../core/service/jb-http-tj.service";
import {DescribeSearchComponent} from "../../search-process/component/describe-search.component";

export type ProcessFormComponent = {
  onSubmit: (values: any) => any,
  initialValue?: any,
  extraActions?: ReactNode[]
}
export function ProcessFormComponent(props: ProcessFormComponent) {
  const [processTypes, setProcessTypes] = useState<ProcessTypeEntity[]>([])
  const [form] = Form.useForm()
  const [publicInfo, setPublicInfo] = useState<any>();
  const {getCourtByProcessCode} = useProcessCodeUtil();
  const courtService = useJbHttpTjService();
  const processTypeRepo = useProcessTypeRepository();
  
  const processCourt = Form.useWatch('processCourt', form);
  
  const onCodeBlur = (code: string) => {
    if (!code) return;
    const court = getCourtByProcessCode(code)
    form.setFieldValue('processCourt', court ? court.toLowerCase() : '')
  }
  
  useEffect(() => {
    processTypeRepo.list().then(res => {
      setProcessTypes(res.data)
    })
  }, [])
  
  useEffect(() => {
    if (props.initialValue) {
      form.setFieldsValue({
        ...props.initialValue,
        code: props.initialValue.code,
        processTypeId: props.initialValue.processType?.id,
        processSubtypeId: props.initialValue.processSubtype?.id,
        objects: props.initialValue?.objects?.map((w:any) => w.id)
      })
    } else {
      form.setFieldsValue({
        enableFollowup: true,
        enableNotification: true,
      })
    }
  }, [props.initialValue, form])
  
  function onSubmit(values: any) {
    return props.onSubmit(values)
  }
  
  const typesOpts = useMemo(() => {
    return processTypes
      .filter(w => w.subtypes?.length)
      .map(w => ({
        label: w.name,
        options: w.subtypes?.map(x => ({
          label: `${x.description}`,
          value: x.id,
          
        }))
      }))
  }, [processTypes])

  const onCheckProcess = useCallback((code: string, court: string) => {
    if (!code || !court) {
      notification.warn({ message: 'Informe o código do processo e o tribuanl que o mesmo pertence' })
      return;
    }
    courtService.search(court, code).then(res => {
      if (res.length) {
        setPublicInfo(res[0])
      } else {
        notification.warning({
          message: `Não foram encontrado dados no Sistema DATAJUD para o código ${code}`
        })
        setPublicInfo(undefined)
      }
    })
  },[])

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onSubmit}
    >
      <Row gutter={12}>
        <Col span={6}>
          <Form.Item name={'processSubtypeId'} label={'Tipo / Subtipo Processual'} rules={[{ required: true }]}>
            <Select options={typesOpts} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={'code'} label={'Código do Processo'}>
            <ProcessCodeInput  onBlur={onCodeBlur} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <CourtDropdown name={'processCourt'} />
        </Col>
        <Col style={{paddingTop: 30}}>
          <Button
            icon={<SearchOutlined />}
            loading={courtService.loading}
            type={'link'}
            onClick={() => {
              onCheckProcess(
                form.getFieldValue('code'),
                form.getFieldValue('processCourt'),
              )
            }}
          >
            Checar no DataJud
          </Button>
        </Col>
      </Row>
      
      <Row gutter={12}>
      
      </Row>
      <Row gutter={20}>
        <Col>
          <Form.Item
            name={'enableFollowup'}
            valuePropName={'checked'}
            label={'Habilitar Acompanhamento Processual Pelo DataJud'}
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={'enableNotification'} valuePropName={'checked'} label={'Habilitar Notificações de Atualização'}>
            <Switch  />
          </Form.Item>
          
        </Col>
      </Row>
      {
        props.initialValue?.id && (
          <CrudProcessObjectAttachedComponent
            processId={props.initialValue?.id}
          />
        )
      }
      <JbFormActionButtonComponent
        extraActions={props.extraActions}
      />
      {
        publicInfo && (
          <Modal
            open
            width={800}
            footer={null}
            onCancel={() => setPublicInfo(undefined)}
          >
            <DescribeSearchComponent data={publicInfo} />
          </Modal>
        )
      }
    </Form>
  )
}

export function ProcessCodeInput(props: any) {
  function validateMask(value: string, mask: string) {
    const cleanValue = value.replace(/\D/g, '');
    let maskedValue = '';
    let valueIndex = 0;
    for (let i = 0; i < mask.length; i++) {
      if (mask[i] === '0') {
        if (valueIndex < cleanValue.length) {
          maskedValue += cleanValue[valueIndex];
          valueIndex++;
        } else {
          break;
        }
      } else {
        maskedValue += mask[i];
      }
    }
    return maskedValue;
  }
  return (
    <Input
      value={props.value}
      onChange={evt =>
        props.onChange(
          validateMask(
            evt.target.value,
            '0000000-00.0000.0.00.0000'
          )
        )}
    />
    
  )
}