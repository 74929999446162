import {createContext} from "react";
import {UserEntity} from "../../context/auth/entity/user.entity";
import {TenantEntity} from "../../domain/entity/tenant.entity";
import {NotificationEntity} from "../../domain/entity/notification.entity";
import {ProfileEntity} from "../../domain/entity/profile.entity";

export const AdminApplicationContext = createContext<AdminApplicationContextProps>({
  applicationPrefix: '',
  showModalContext: () => null,
  readNotifications: () => null,
  notifications: [],
  showChangePasswdModal: false,
  setShowChangePasswdModal: () => null,
  showProfileModal: () => null,
})


export type AdminApplicationContextProps = {
  user?: UserEntity;
  currentProfile?: ProfileEntity,
  currentTenant?: TenantEntity,
  applicationPrefix: string;
  showModalContext: () => any,
  readNotifications: (notifications: string[]) => any,
  notifications: NotificationEntity[],
  showChangePasswdModal: boolean,
  setShowChangePasswdModal: (show: boolean) => any,
  showProfileModal: () => any,
}