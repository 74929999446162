import {Menu} from "antd";
import {useHistory} from "react-router-dom";
import {
  CalendarOutlined,
  DashboardOutlined,
  DollarOutlined,
  ScheduleOutlined,
  SearchOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  UserOutlined
} from "@ant-design/icons";
import {useContext, useMemo} from "react";
import {AdminApplicationContext} from "../../../../core/context/admin-application.context";

export type MenuItem = {
  key: string | number,
  label: string,
  icon?: any,
  children?: MenuItem[],
  path?: string
}

const menus: any[] = [
  {
    key: 0,
    link: 'calendar',
    label: 'Calendário',
    icon: <CalendarOutlined  />,
  },
  {
    key: 10,
    label: 'Meu Trabalho',
    link: 'my-work',
    icon: <UserOutlined />,
  },
  {
    key: 20,
    label: 'Parametrizações',
    icon: <SettingOutlined />,
    children: [
      {
        key: 'part-type',
        label: "Tipos de Parte"
      },
      {
        key: 'process-type',
        label: "Tipos de Processo"
      },
      {
        key: 'step-type',
        label: "Tipo Movimentação"
      },
      {
        key: 'attendance-type',
        label: "Tipo Atendimento"
      },
      {
        key: 'process-object',
        label: "Objetos de Processo"
      },
      {
        key: 'workflow',
        label: "Workflows"
      },
    ],
  },
  {
    key: 30,
    label: 'Atendimentos',
    icon: <ScheduleOutlined />,
    children: [
      {
        key: 'client',
        label: 'Clientes'
      },
      {
        key: 'external-attendance',
        label: 'Atendimentos Externos'
      },
      {
        key: 'attendance',
        label: 'Atendimentos'
      },
    ]
  },
  {
    key: 31,
    label: 'Financeiro',
    icon: <DollarOutlined />,
    children: [
      {
        key: 'supplier',
        label: 'Fornecedores'
      },
      {
        key: 'finance-category',
        label: 'Categorias'
      },
      {
        key: 'finance-item',
        label: 'Item'
      },
      {
        key: 'finance-entry',
        label: 'Lançamentos'
      },
      // {
      //   key: 'finance-dashboard',
      //   label: 'Dashboard'
      // },
      {
        key: 'cash-flow',
        label: 'Fluxo de Caixa'
      },
    ]
  },
  {
    key: 32,
    label: 'Consultas',
    icon: <SearchOutlined />,
    children: [
      {
        key: 'search_process',
        label: 'Processos'
      },
    ]
  },
  {
    key: 40,
    label: 'Segurança',
    icon: <SecurityScanOutlined />,
    children: [
      {
        key: 'user',
        label: 'Usuários'
      },
      {
        key: 'profile',
        label: 'Perfil de Acesso'
      },
    ]
  },
  {
    key: 50,
    link: 'dashboard',
    label: 'Dashboard',
    icon: <DashboardOutlined />,
  },
  {
    key: 99,
    label: 'Super Administrador',
    icon: <SecurityScanOutlined />,
    link: 'super-admin',
    superUserAccess: true
  }
]

export function MenuCoreComponent (props: any) {
  const history = useHistory()
  const { user, currentProfile } = useContext<any>(AdminApplicationContext);
  
  const resourceMap = useMemo(() => {
    const resourceMap = new Map<string, { read: boolean, write: boolean}>();
    if (currentProfile) {
      for (const resource of currentProfile.resources) {
        if (!resource?.resource?.name) continue;
        resourceMap.set(
          resource.resource.name,
          {
            read: resource.read,
            write: resource.write,
          }
        )
      }
      return resourceMap;
    }
  }, [currentProfile])
  
  const parsedMenus = useMemo(() => {
    const checkMenu = (propName: string) => (item: any) => user?.superUser || !Object.hasOwn(item, propName) || !!resourceMap?.get(item[propName])?.read
    return menus
      .filter(w => (w.superUserAccess && user?.superUser) || (!w.superUserAccess))
      .filter(checkMenu('link'))
      .map(item => ({
      ...item,
      onClick: () => !!item.link ? history.push(`/admin/${item.link}`) : null,
      children: item?.children?.filter(checkMenu('key')).map((child: any) => ({
        ...child,
        onClick: () => history.push(`/admin/${child.key}`)
      }))
    })).filter(menu => menu.link || menu.children.length)
  }, [user])

  return (
    <Menu theme={'dark'} items={parsedMenus} mode={'inline'}  />
  )
}
