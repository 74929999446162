import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {UserEntity} from "../entity/user.entity";
import {BaseResponse} from "../../core/common/base-response.common";
import {useCallback} from "react";

export function useUserRepository() {
  const crud = useJbHttpCrudService<UserEntity>('user')
  function checkUser(field: string, value: string): Promise<BaseResponse<UserEntity>> {
    return crud.get(
      `${crud.resourceName}/check/${field}/${value}`
    )
  }
  
  function changePassword(userId: string, newPassword: string) {
    return crud.put(
      `${crud.resourceName}/${userId}/change-password`,
      { newPassword }
    )
  }
  
  function changeMyPassword(currentPassword: string, newPassword: string) {
    return crud.put(
      `${crud.resourceName}/change-my-password`,
      {
        confirmCurrentPassword: currentPassword,
        newPassword
      }
    )
  }
  
  function resetUserPassword(userId: string) {
    return crud.post(
      `${crud.resourceName}/${userId}/reset-user-password`,
      {}
    )
  }
  
  function setProfile(userId: string, profileId: string, operation: 'include' | 'remove'): Promise<BaseResponse<UserEntity>> {
    return  crud.post(
      `${crud.resourceName}/${userId}/set-profile`,
      {
        profileId,
        operation
      }
    )
  }
  
  const changeProfile = useCallback(
    (profileId: string) => {
      return crud.post(
        `${crud.resourceName}/change-profile/${profileId}`,
        {}
      )
    }, []
  )
  
  return {
    ...crud,
    checkUser,
    changeMyPassword,
    changePassword,
    resetUserPassword,
    setProfile,
    changeProfile
  }
}
