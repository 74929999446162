import {Layout} from "antd";
import React, {useContext} from "react";
import {Route, Switch} from "react-router-dom";
import {AccountButtonComponent} from "../account-button";
import {UserModule} from "../../module/user";
import {StepTypeModule} from "../../module/step-type";
import {PartTypeModule} from "../../module/part-type";
import {ProcessTypeModule} from "../../module/process-type";
import {ClientModule} from "../../module/client";
import {AttendanceModule} from "../../module/attendance";
import {CalendarModule} from "../../module/calendar";
import {AdminApplicationContext} from "../../../../core/context/admin-application.context";
import {MyWorkModule} from "../../module/my-work";
import {ProcessObjectModule} from "../../module/process-object";
import {SuperAdminModule} from "../../module/super-admin";
import {AttendanceTypeModule} from "../../module/attendance-type";
import {DashboardModule} from "../../module/dashboard";
import {ExternalAttendanceModule} from "../../module/external-attendance";
import {NotificationButton} from "../notification-button";
import {ActionButtonGrid} from "../action-button-grid";
import {GlobalSearchButton} from "../global-search-button";
import {WorkflowModule} from "../../module/workflow";
import {SearchProcessModule} from "../../module/search-process";
import {FinanceEntryModule} from "../../module/finance-entry";
import {FinanceDashboardModule} from "../../module/finance-dashboard";
import {FinanceCategoryModule} from "../../module/finance-category";
import {FinanceItemModule} from "../../module/finance-item";
import {SupplierModule} from "../../module/supplier";
import {CashFlowModule} from "../../module/cash-flow";
import {ProfileModule} from "../../module/profile";

const { Footer, Content } = Layout

export function WrapperContent() {
  const { applicationPrefix } = useContext<any>(AdminApplicationContext);
  function makeUrl(path: string) {
    return `/${applicationPrefix}${path}`
  }
  return (
    <Layout className="site-layout">
      <Content style={{ padding: '15px 15px 15px', }} >
        <ActionButtonGrid>
          <GlobalSearchButton />
          <NotificationButton />
          <AccountButtonComponent />
        </ActionButtonGrid>
        <div style={{padding: 24, minHeight: 360, backgroundColor: 'white' }}>
            <Switch>
              <Route key={'user'} path={`/admin/user`} render={() => <UserModule />} />
              <Route key={'step-type'} path={`/admin/step-type`} render={() => <StepTypeModule />} />
              <Route key={'attendance-type'} path={`/admin/attendance-type`} render={() => <AttendanceTypeModule />} />
              <Route key={'part-type'} path={`/admin/part-type`} render={() => <PartTypeModule />} />
              <Route key={'process-type'} path={`/admin/process-type`} render={() => <ProcessTypeModule />} />
              <Route key={'client'} path={`/admin/client`} render={() => <ClientModule />} />
              <Route key={'attendance'} path={makeUrl('/attendance')} render={() => <AttendanceModule />} />
              <Route key={'my-work'} path={makeUrl('/my-work')} render={() => <MyWorkModule />} />
              <Route key={'process-object'} path={makeUrl('/process-object')} render={() => <ProcessObjectModule />} />
              <Route key={'super-admin'} path={makeUrl('/super-admin')} render={() => <SuperAdminModule />} />
              <Route key={'calendar'} path={makeUrl('/calendar')} render={() => <CalendarModule />} />
              <Route key={'dashboard'} path={makeUrl('/dashboard')} render={() => <DashboardModule />} />
              <Route key={'external-attendance'} path={makeUrl('/external-attendance')} render={() => <ExternalAttendanceModule />} />
              <Route key={'workflow'} path={makeUrl('/workflow')} render={() => <WorkflowModule />} />
              <Route key={'search_process'} path={makeUrl('/search_process')} render={() => <SearchProcessModule />} />
              <Route key={'finance-entry'} path={makeUrl('/finance-entry')} render={() => <FinanceEntryModule />} />
              <Route key={'finance-dashboard'} path={makeUrl('/finance-dashboard')} render={() => <FinanceDashboardModule />} />
              <Route key={'category'} path={makeUrl('/finance-category')} render={() => <FinanceCategoryModule />} />
              <Route key={'finance-item'} path={makeUrl('/finance-item')} render={() => <FinanceItemModule />} />
              <Route key={'supplier'} path={makeUrl('/supplier')} render={() => <SupplierModule />} />
              <Route key={'cash-flow'} path={makeUrl('/cash-flow')} render={() => <CashFlowModule />} />
              <Route key={'profile'} path={makeUrl('/profile')} render={() => <ProfileModule />} />
              <Route key={'default'} path={makeUrl('')} render={() => <CalendarModule />} />
            </Switch>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center', backgroundColor: 'none', background: 'none'}}>
        Licenciado Para Juristta Business 2022
      </Footer>
    </Layout>
  )
}
