import {ProcessEntity} from "../../domain/entity/process.entity";
import React from "react";
import {useProcessRepository} from "../../domain/repository/process.repository";
import {Button} from "antd";
import {ProcessFormComponent} from "../process.form.component";
import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {ArrowRightOutlined} from "@ant-design/icons";

export function ProcessStep(props: {
  nextStep: () => any,
  attendance: AttendanceEntity,
  process?: ProcessEntity,
  setProcess: (process: ProcessEntity) => any
}) {
  const processRepo = useProcessRepository();

  function onSubmit(values: any) {
    console.log(values)
    if (props.process) {
      return processRepo
        .edit(props.process.id, values)
        .then(res => onFinish(res))
        .catch(err => onError(err))
    } else {
      values.attendanceId = props.attendance.id
      return processRepo.create(values)
        .then(res => onFinish(res))
        .catch(err => onError(err))
    }
  }
  

  function onFinish(res: any) {
    props.setProcess(res.data);
  }

  function onError(err: any) {
  
  }
  
  
  return (
    <>
      <ProcessFormComponent
        onSubmit={onSubmit}
        initialValue={props.process}
        extraActions={[
          <Button icon={<ArrowRightOutlined />} onClick={() => props.nextStep()}>
            Continuar
          </Button>
        ]}
      />
    </>
  )
}
