import {ProfileFormComponent} from "../component/profile.form.component";
import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {FinanceItemEntity} from "../../../../../domain/entity/finance-item.entity";
import {useSupplierRepository} from "../../../../../domain/repository/supplier.repository";
import {ProfileEntity} from "../../../../../domain/entity/profile.entity";
import {useProfileRepository} from "../../../../../domain/repository/profile.repository";

export function EditProfilePage(props: ModulePageProps) {
  const [profile, setProfile] = useState<ProfileEntity>();
  const { id } = useParams<any>()
  const profileRepo = useProfileRepository();
  
  useEffect(() => {
    profileRepo.find(id).then(res => setProfile(res.data));
  }, [id]);
  
  function onSave(values: any) {
    profileRepo.edit(id, values).then(() => {
      props.navigator('list')
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent title={'Editar Perfil ' + profile?.name} />
      <ProfileFormComponent
        onSubmit={onSave}
        currentValues={profile}
        onCancel={() => props.navigator('list')}
      />
    </>
  )
}