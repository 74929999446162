import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {ProfileEntity} from "../entity/profile.entity";
import {BaseResponse} from "../../core/common/base-response.common";

export function useProfileRepository() {
  const crud = useJbHttpCrudService<ProfileEntity>('profile');
  
  function setProfileResource(
    profileId: string,
    resource: string,
    read: boolean,
    write: boolean,
  ): Promise<BaseResponse<any>> {
    return crud.put(
      `profile/${profileId}/resource`,
      {
        resource,
        read,
        write
      }
    )
  }
  
  return {
    ...crud,
    setProfileResource,
  }
}