import {useForm} from "antd/es/form/Form";
import {useEffect, useState} from "react";
import {Col, Form, Input, Row, Select} from "antd";
import {JbFormActionButtonComponent} from "../../../core/component/jb-form-action-button.component";
import {MaskedInput} from "antd-mask-input";
import {SupplierEntity} from "../../../../../domain/entity/supplier.entity";

export function ProfileFormComponent(props: {
  currentValues?: Partial<SupplierEntity>,
  onSubmit: (values: any) => any,
  onCancel: () => any,
}) {
  const [form] = useForm()
  
  
  useEffect(() => {
    if (props.currentValues) {
      form.setFieldsValue({
        ...props.currentValues,
      });
    }
  }, [form, props.currentValues]);
  
  function onSubmit(values: any) {
    return props.onSubmit({
      ...values,
    });
  }
  
  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onSubmit}
    >
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name={'name'} label={'Nome'} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item name={'description'} label={'Descrição'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <JbFormActionButtonComponent
        onCancel={props.onCancel}
      />
    </Form>
  )
}