import {Checkbox, Table} from "antd";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ProfileEntity} from "../../../../../domain/entity/profile.entity";
import {useProfileRepository} from "../../../../../domain/repository/profile.repository";
import {UserEntity} from "../../../../../domain/entity/user.entity";
import {useUserRepository} from "../../../../../domain/repository/user.repository";

export function UserProfileFormComponent(props: {
  userId: string
}) {
  const [profiles, setProfiles] = useState<ProfileEntity[]>([]);
  const [user, setUser] = useState<UserEntity>()
  const profileRepo = useProfileRepository();
  const userRepo = useUserRepository();
  
  useEffect(() => {
    profileRepo.list().then(res => setProfiles(res.data));
  }, []);
  
  const loadUser = useCallback(() => {
    userRepo.find(props.userId).then(res => setUser(res.data))
  }, [])
  
  useEffect(() => {
    loadUser()
  }, [loadUser, props.userId]);
  
  const currentProfiles = useMemo(() => {
    return new Set(user?.profiles?.map(w => w.id))
  }, [user])
  
  
  const onInclude = useCallback((profileId: string) => {
    userRepo.setProfile(props.userId, profileId, 'include').then(loadUser)
  }, [loadUser]);
  
  const onRemove = useCallback((profileId: string) => {
    userRepo.setProfile(props.userId, profileId, 'remove').then(loadUser)
  }, [loadUser]);
  
  return (
    <Table
      dataSource={profiles}
      pagination={false}
      columns={[
        {
          title: 'Perfil de Acesso',
          dataIndex: 'name',
        },
        {
          title: '',
          dataIndex: 'id',
          render: profileId => (
            <Checkbox
              checked={currentProfiles.has(profileId)}
              onChange={evt => {
                if (evt.target.checked) {
                  onInclude(profileId)
                } else {
                  onRemove(profileId)
                }
              }}
            />
          )
        }
      ]}
    />
  )
}