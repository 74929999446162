import React, {useEffect, useState} from "react";
import {Layout} from "antd";
import {MenuCoreComponent} from "./core/menu/menu.core.component";
import {LogoCoreComponent} from "./core/logo";
import {WrapperContent} from "./core/wrapper-content";
import {BrowserRouter} from "react-router-dom";
import {useJbTokenStorageService} from "../../core/service/jb-token-storage.service";
import {useJbHttpAuthService} from "../../core/service/jb-http-auth.service";
import {AdminApplicationContext} from "../../core/context/admin-application.context";
import {UserEntity} from "../auth/entity/user.entity";
import {ContextModal} from "./core/context-modal";
import {TenantEntity} from "../../domain/entity/tenant.entity";
import {NotificationEntity} from "../../domain/entity/notification.entity";
import {useNotificationRepository} from "../../domain/repository/notification.repository";
import {ChangePasswordFormModal} from "./core/change-password/change-password-form.modal";
import {FirstLoginChangePasswordComponent} from "./core/change-password/first-login-change-password.component";
import {ProfileEntity} from "../../domain/entity/profile.entity";
import {ProfileModal} from "./core/profile-modal";

const { Sider } = Layout

export function AdminContext(props: { prefix: string }) {
  const [collapsed, setCollapsed] = useState(false);
  const [currentProfile, setCurrentProfile] = useState<ProfileEntity>();
  const [showChangePasswdModal, setShowChangePasswdModal] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserEntity>()
  const [currentTenant, setCurrentTenant] = useState<TenantEntity>()
  const [notification, setNotifications] = useState<NotificationEntity[]>([])
  const [showContextModal, setShowContextModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const tokenService = useJbTokenStorageService();
  const authService = useJbHttpAuthService();
  const notificationRepo = useNotificationRepository();

  function refreshUser() {
    authService.refreshUser().then(res => {
      tokenService.setToken(res.data.token)
      tokenService.setRefreshToken(res.data.refreshToken);
      setCurrentUser(res.data.user)
    })
  }
  
  function describeUser() {
    return authService.describeMe().then(res => {
      setCurrentUser(res.data.user)
      setCurrentTenant(res.data.tenant)
      if (res.data.user.selectedProfile) {
        setCurrentProfile(res.data.user.selectedProfile)
      }
    })
  }
  
  function getPendingNotifications() {
    notificationRepo.listNotifications().then(res => setNotifications(res.data))
  }
  
  useEffect(() => {
    const refresher = setInterval(() => {
      refreshUser()
      describeUser()
      getPendingNotifications();
    }, 1000 * 60)
    describeUser()
    getPendingNotifications();
    return () => clearInterval(refresher)
  },[])
  
  function readNotifications(ids: string[]) {
    return notificationRepo.readNotifications(ids)
      .then(() => getPendingNotifications())
  }

  return (
    <BrowserRouter>
      <AdminApplicationContext.Provider value={{
        user: currentUser,
        currentTenant: currentTenant,
        currentProfile,
        applicationPrefix: props.prefix,
        showModalContext: () => setShowContextModal(true),
        notifications: notification,
        readNotifications,
        setShowChangePasswdModal: setShowChangePasswdModal,
        showChangePasswdModal: showChangePasswdModal,
        showProfileModal: () => setShowProfileModal(true),
      }}>
        <Layout style={{minHeight: '100vh',}} >
          <Sider
            collapsed={collapsed}
            onCollapse={(value: any) => setCollapsed(value)}
          >
            <LogoCoreComponent />
            <MenuCoreComponent />
          </Sider>
          <WrapperContent />
        </Layout>
        <ContextModal
          currentTenant={currentTenant as TenantEntity}
          open={showContextModal}
          onClose={() => setShowContextModal(false)}
          onChanged={() => describeUser().then(() => window.location.reload())}
        />
        <ChangePasswordFormModal
          onClose={() => setShowChangePasswdModal(false)}
          open={showChangePasswdModal}
          refreshUser={describeUser}
        />
        <FirstLoginChangePasswordComponent
          refreshUser={() => setTimeout(describeUser,500)}
        />
        {
          showProfileModal && (
            <ProfileModal
              onClose={() => setShowProfileModal(false)}
              onChanged={() => setTimeout(describeUser,500)}
            />
          )
        }
      </AdminApplicationContext.Provider>
    </BrowserRouter>
  )
}
