import {ProfileFormComponent} from "../component/profile.form.component";
import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {useProfileRepository} from "../../../../../domain/repository/profile.repository";

export function CreateProfilePage(props: ModulePageProps) {
  const profileRepo = useProfileRepository();
  
  function onCreate(values: any) {
    profileRepo.create(values).then(() => {
      props.navigator('list')
    })
  }
  
  return (
    <>
      <JbPageHeaderComponent title={'Novo Item'} />
      <ProfileFormComponent
        onSubmit={onCreate}
        onCancel={() => props.navigator('list')}
      />
    </>
  )
}