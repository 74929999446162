import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {Button, Modal, Table} from "antd";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {FinanceItemEntity} from "../../../../../domain/entity/finance-item.entity";
import {useSupplierRepository} from "../../../../../domain/repository/supplier.repository";
import {ProfileEntity} from "../../../../../domain/entity/profile.entity";
import {ResourceProfileFormComponent} from "../component/resource-profile.form.component";
import {useProfileRepository} from "../../../../../domain/repository/profile.repository";
import {JbButtonComponent} from "../../../core/component/jb-button.component";
import {SecurityScanFilled, SecurityScanOutlined} from "@ant-design/icons";
import {JbButtonWithPopover} from "../../../core/button-with-popover";

export function ListProfilePage(props: ModulePageProps) {
  const [items, setItems] = useState<ProfileEntity[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<ProfileEntity>();
  const profileRepository = useProfileRepository();
  
  const loadData = useCallback(() => {
    profileRepository.list().then(res => setItems(res.data))
  }, [])
  
  useEffect(() => {
    loadData()
  }, []);
  
  function onRemove(id: string) {
    profileRepository.remove(id).then(() => loadData());
  }

  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        key: 1,
        title: 'Nome',
        dataIndex: 'name'
      },
      {
        key: 1,
        title: 'Descrição',
        dataIndex: 'description'
      },
      {
        title: '',
        key: 99,
        align: 'right',
        dataIndex: 'id',
        render: (id, row) => (
          <JbTableRowActionsComponent
            onEdit={() => props.navigator('edit/' + id)}
            onRemove={() => onRemove(id)}
            extra={[
              <JbButtonWithPopover
                type={'link'}
                size={'small'}
                onClick={() => setSelectedProfile(row)}
                icon={<SecurityScanOutlined />}
                title={'Recursos de Acesso'}
              />
            ]}
          />
        )
      }
    ]
  }, [])
  
  return(
    <>
      <JbPageHeaderComponent
        title={'Segurança / Perfil de Acesso'}
        description={'Gestão de Acesso ao Sistema'}
        onCreateLink={`${props.prefix}/create`}
      />
      <Table
        loading={profileRepository.loading}
        dataSource={items}
        columns={columns}
      />
      
      {
        selectedProfile && (
          <Modal
            width={600}
            footer={null}
            onCancel={() => setSelectedProfile(undefined)}
            open
          >
            <ResourceProfileFormComponent
              profile={selectedProfile as ProfileEntity}
              
            />
          </Modal>
        )
      }
    </>
  )
}
