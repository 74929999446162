import React, {useEffect, useMemo, useState} from 'react';
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import {UserEntity} from "../../../../../domain/entity/user.entity";
import {useUserRepository} from "../../../../../domain/repository/user.repository";
import {Modal, Table, Tag, Typography} from "antd";
import {ColumnsType} from "antd/es/table";
import {ModulePageProps} from "../../../core/module-pages";
import {JbTableRowActionsComponent} from "../../../core/component/jb-table-row-actions.component";
import {FileProtectOutlined, PlayCircleOutlined, ProfileOutlined, StopOutlined} from "@ant-design/icons";
import {JbButtonWithPopover} from "../../../core/button-with-popover";
import {UserProfileFormComponent} from "../component/user-profile-form.component";

export function ListUserPage(props: ModulePageProps) {
  const [users, setUsers] = useState<UserEntity[]>([])
  const [showProfilesModal, setShowProfilesModal] = useState<UserEntity>()
  const [userToResetPassword, showUserToResetPassword] = useState<UserEntity>();
  const { list: listUsers, loading, edit, resetUserPassword } = useUserRepository()

  useEffect(() => {
    listUsers().then(res => setUsers(res.data))
  }, [])

  function toggleUser(id: string, currentStatus: boolean) {
    return edit(id, { active: !currentStatus }).then(
      () => listUsers().then(res => setUsers(res.data))
    )
  }
  
  function onResetPassword(userId: string) {
    resetUserPassword(userId).then(() => showUserToResetPassword(undefined))
  }

  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        key: 1,
        dataIndex: 'person',
        title: 'Nome',
        render: person => person.name
      },
      {
        key: 2,
        dataIndex: 'person',
        title: 'Email',
        render: person => person.email
      },
      {
        key: 3,
        dataIndex: 'person',
        title: 'Documento',
        render: person => person.document
      },
      {
        key: 4,
        dataIndex: 'username',
        title: 'Usuário',
      },
      {
        key: 5,
        dataIndex: 'active',
        title: 'Ativo',
        render: active => active ? (<Tag color={'green'}>Sim</Tag>) : (<Tag>Não</Tag>)
      },
      {
        key: 99,
        dataIndex: 'id',
        title: '',
        render: (id, row) => (
          <JbTableRowActionsComponent
            onEdit={() => props.navigator(`edit/${id}`)}
            extra={[
              row.active ? (
                <JbButtonWithPopover
                  title={'Desabilitar'}
                  icon={<StopOutlined />}
                  type={'link'}
                  onClick={() => toggleUser(id, row.active)}
                  style={{color: 'red'}}
                  size={'small'}
                />
              ) : (
                <JbButtonWithPopover
                  title={'Habilitar'}
                  style={{color: 'green'}}
                  icon={<PlayCircleOutlined />}
                  onClick={() => toggleUser(id, row.active)}
                  type={'link'}
                  size={'small'}
                />
              ),
              <JbButtonWithPopover
                title={'Perfis de Acesso'}
                icon={<ProfileOutlined />}
                onClick={() => setShowProfilesModal(row)}
                type={'link'}
                size={'small'}
              />,
              <JbButtonWithPopover
                title={'Resetar Senha'}
                icon={<FileProtectOutlined />}
                onClick={() => showUserToResetPassword(row)}
                type={'link'}
                size={'small'}
              />
            ]}
          />
        )
      },
    ]
  }, [])

  return (
    <div>
      <JbPageHeaderComponent
        title={'Segurança / Usuários'}
        description={'Gestão dos Usuários do Sistema'}
        onCreateLink={`${props.prefix}/create`}
      />
      <Table
        columns={columns}
        dataSource={users}
        loading={loading}
      />
      {
        userToResetPassword && (
          <Modal
            open
            onCancel={() => showUserToResetPassword(undefined)}
            onOk={() => onResetPassword(userToResetPassword?.id)}
            title={'Resetar Senha'}
            >
            <Typography.Text>
              Ao confirmar a operação, uma nova senha será gerada para <strong>{userToResetPassword.person?.name}</strong> e enviado ao email <strong>{userToResetPassword.person?.email}</strong>. Deseja prosseguir ?
            </Typography.Text>
          </Modal>
        )
      }
      
      {
        showProfilesModal && (
          <Modal
            open
            width={600}
            bodyStyle={{ padding: 0 }}
            footer={null}
            onCancel={() => setShowProfilesModal(undefined)}
          >
            <UserProfileFormComponent userId={showProfilesModal.id} />
          </Modal>
        )
      }
    </div>
  );
}
