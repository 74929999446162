import {ColumnsType} from "antd/es/table/Table";
import {Checkbox, Table} from "antd";
import {useCallback, useEffect, useMemo, useState} from "react";
import {JbResourceEntity} from "../../../../../domain/entity/jb-resource.entity";
import {useJbResourceRepository} from "../../../../../domain/repository/jb-resource.repository";
import {ProfileEntity, ProfileResourceEntity} from "../../../../../domain/entity/profile.entity";
import {useProfileRepository} from "../../../../../domain/repository/profile.repository";

export function ResourceProfileFormComponent(props: {
  profile: ProfileEntity,
}) {
  const [resources, setResources] = useState<JbResourceEntity[]>([]);
  const [profileResources, setProfileResources] = useState<ProfileResourceEntity[]>([]);
  
  const profileRepo = useProfileRepository();
  const resourceRepo = useJbResourceRepository();
  
  const loadProfileResources = useCallback(() => {
    profileRepo.find(props.profile?.id).then(res => setProfileResources(res.data.resources))
  }, [])
  
  useEffect(() => {
    resourceRepo.list().then(res => setResources(res.data));
  }, []);
  
  useEffect(() => {
    loadProfileResources()
  }, [loadProfileResources, props.profile]);
  
  const onChangePermission = useCallback(
    (resource: string, read: boolean, write: boolean) => {
      return profileRepo.setProfileResource(
        props.profile.id,
        resource,
        read,
        write
      ).then(() => loadProfileResources())
    }, []
  )
  
  const mapResources = useMemo(() => {
    const map = new Map<string, ProfileResourceEntity>();
    for (const resource of profileResources) {
      map.set(resource?.resource?.name, resource)
    }
    return map;
  }, [profileResources])
  
  const columns: ColumnsType<any> = [
    {
      title: 'Recurso',
      dataIndex: 'description'
    },
    {
      title: 'Leitura',
      render: (_, row) => (
        <>
          <Checkbox
            checked={mapResources.get(row.name)?.read}
            onChange={e => onChangePermission(
              row.name, 
              !mapResources.get(row.name)?.read,
              mapResources.get(row.name)?.write ?? false
            )}
          />
        </>
      )
    },
    {
      title: 'Edição',
      render: (_, row) => (
        <>
          <Checkbox
            checked={mapResources.get(row.name)?.write}
            onChange={e => onChangePermission(
              row.name,
              mapResources.get(row.name)?.read ?? false,
              !mapResources.get(row.name)?.write
            )}
          />
        </>
      )
    }
  ]
  
  
  return (
    <>
      <Table
        columns={columns}
        dataSource={resources}
      />
    </>
  )
}