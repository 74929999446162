import {useCallback, useMemo} from "react";


export function useProcessCodeUtil() {
  
  const mapCourt = useMemo(
    () => {
      const mapCourtCodes = new Map<number, string>();
      mapCourtCodes.set(11, 'TJRO');
      mapCourtCodes.set(12, 'TJAC');
      mapCourtCodes.set(13, 'TJMG');
      mapCourtCodes.set(14, 'TJAM');
      mapCourtCodes.set(15, 'TJRR');
      mapCourtCodes.set(16, 'TJPA');
      mapCourtCodes.set(17, 'TJAP');
      mapCourtCodes.set(18, 'TJTO');
      mapCourtCodes.set(19, 'TJMA');
      mapCourtCodes.set(20, 'TJPI');
      mapCourtCodes.set(21, 'TJCE');
      mapCourtCodes.set(22, 'TJRN');
      mapCourtCodes.set(23, 'TJPB');
      mapCourtCodes.set(24, 'TJPE');
      mapCourtCodes.set(25, 'TJAL');
      mapCourtCodes.set(26, 'TJSE');
      mapCourtCodes.set(27, 'TJBA');
      mapCourtCodes.set(28, 'TJES');
      mapCourtCodes.set(29, 'TJRJ');
      mapCourtCodes.set(30, 'TJSP');
      mapCourtCodes.set(31, 'TJPR');
      mapCourtCodes.set(32, 'TJSC');
      mapCourtCodes.set(33, 'TJRS');
      mapCourtCodes.set(34, 'TJMS');
      mapCourtCodes.set(35, 'TJMT');
      mapCourtCodes.set(36, 'TJGO');
      mapCourtCodes.set(36, 'TJDF');
      return mapCourtCodes;
    }, []
  )
  
  const getCourtByProcessCode = useCallback((code: string) => {
    const [segmentCode, courtCode] = code.split('.').slice(2);
    if (segmentCode === '8') {
      return mapCourt.get(parseInt(courtCode));
    }
    return null;
  }, [mapCourt])
  
  const getSegment = useCallback((segmentCode: string) => {
    switch (segmentCode) {
      case '1':
        return 'Supremo Tribunal Federal';
      case '2':
        return 'Conselho Nacional de Justiça';
      case '3':
        return 'Superior Tribunal de Justiça';
      case '4':
        return 'Justiça Federal';
      case '5':
        return 'Justça do Trabalho';
      case '6':
        return 'Juestiça Eleitoral';
      case '7':
        return 'Justiça Militar da União';
      case '8':
        return 'Justiça dos Estados e do Distrito Federal e Territórios';
      case '9':
        return 'Justiça Militar Estadual';
      default:
        return 'Não identificado'
    }
  }, [])
  
  const decode = useCallback((code: string) => {
    const [processNumber, processYear, segmentCode, courtCode] = code.split('.');
    const segment = getSegment(segmentCode);
    return {
      processNumber,
      processYear,
      segmentCode,
      courtCode,
      segment
    }
  }, [getSegment])
  
  return {
    getCourtByProcessCode,
    decode
  }
}