import {useCallback, useContext} from "react";
import {AdminApplicationContext, AdminApplicationContextProps} from "../context/admin-application.context";

export function useCheckPermissionUtl() {
  const { currentProfile } = useContext<AdminApplicationContextProps>(AdminApplicationContext);
  
  
  const hasFinanceEntryPermission = useCallback((write = false) => {
    for (const { resource} of currentProfile?.resources ?? []) {
      if (resource.name === 'finance_entry') {
        return true;
      }
    }
    return false;
  }, [currentProfile])

  
  return {
    hasFinanceEntryPermission
  }
}