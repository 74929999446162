import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {JbResourceEntity} from "../entity/jb-resource.entity";


export function useJbResourceRepository() {
  const { list } = useJbHttpCrudService<JbResourceEntity>('resource');
  
  return {
    list
  };
}