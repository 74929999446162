import {Button, List, Modal} from "antd";
import {useCallback, useContext} from "react";
import {
  AdminApplicationContext,
  AdminApplicationContextProps
} from "../../../../core/context/admin-application.context";
import {useUserRepository} from "../../../../domain/repository/user.repository";

export function ProfileModal(props: {
  onChanged: () => any,
  onClose: () => any,
}) {
  const { user, currentProfile } = useContext<AdminApplicationContextProps>(AdminApplicationContext);
  const userRepository = useUserRepository();
  
  const chancgeProfile = useCallback((profileId: string) => {
    userRepository.changeProfile(profileId).then(() => {
      props.onChanged();
    })
  }, [])
  return (
    <Modal
      title={'Selecionar Perfil'}
      footer={null}
      onCancel={props.onClose}
      open
    >
      <List
        dataSource={user?.profiles}
        renderItem={profile => (
          <List.Item
            extra={[
              profile.id === currentProfile?.id ?
                <Button type={'primary'} size={'small'} shape={'round'}>Selecionado</Button>
              :
                <Button onClick={() => chancgeProfile(profile.id)} type={'link'} size={'small'}>Selecionar</Button>
            ]}
          >
            <List.Item.Meta
              title={profile.name}
              description={profile.description}
            />
          </List.Item>
        )}
      />
    </Modal>
  )
}